@import '/apps/wt/webtools/sparta_build_server/tmp/2BD26A73/artifacts/components/utilities/global/sparta-style-utility/3.2.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
@import '_partials/_bol-header'; 
@import '_partials/_header-mobile'; 
@import '_partials/_bol-nav-header-backto';
@import '_partials/_multilingual-tooltip.scss';

[data-sparta-container] section.head-row {
  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.16); 
}

.unauth .dd-menu .dd-menu__nav li.dd-menu__item:first-child {
  padding-left: 0;
}

@media (max-width: 767px) {
  [data-sparta-container] section.head-row {
    box-shadow: none; 
    display: inline-block;
    width: 100%;
    background-color: white;
  }

  .unauth .dd-menu {
    display: block !important; 
  }
}

[data-sparta-container] .disclosureMessage-area .spartaMessage.small {
  font-size: 11px !important;
  line-height: 14px !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}

.bol-component {
  @import '_bol-smc-nav.scss';
  @import '_bol-smc-tab.scss';
}


[data-sparta-container] .dd-submenu>.bol-subnav>.bol-subnav__item>.bol-subnav__plan,
.bol-container-wrapper .dd-submenu>.bol-subnav>.bol-subnav__item>.bol-subnav__plan {
  font-family: "cnx-regular", Arial, Helvetica, sans-serif;
  color: #000000;
  text-decoration: none;
  padding: 5px 15px;
  width: 100%;
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

[data-sparta-container] .dd-submenu--myaccounts>.bol-subnav>.bol-subnav__item>.bol-subnav__plan,
.bol-container-wrapper .dd-submenu--myaccounts>.bol-subnav>.bol-subnav__item>.bol-subnav__plan {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}


@media (max-width: 767px) {
  #BOLHeader .bol-header .welcome {display: block !important;}
  #BOLHeader #spanWelcomeUser {display: none !important;}
}