.bol-smc-nav {
  margin-bottom: -10px !important; 

  &__icon-mail {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    width: 44px;
    height: 44px;
    background-position: center center;
    background-size: auto;
    border: 1px solid transparent;
    position: relative;

    &:hover,
    &:focus {
      border-color: #0053c2 !important;
      border-radius: 2px !important;
      outline: 0;
      box-sizing: border-box;

      &::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTIwIDRINGEyIDIgMCAwMC0yIDJ2MTJhMiAyIDAgMDAyIDJoMTZhMiAyIDAgMDAyLTJWNmEyIDIgMCAwMC0yLTJ6bS04IDYuODJMNC4zMiA2aDE1LjM2TDEyIDEwLjgyek00IDE4VjguMTZsOCA1IDgtNVYxOEg0eiIgZmlsbD0iIzAwNTNjMiIvPjwvc3ZnPg==);
      }
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      transform: translate(-50%, -50%);
    }

    &::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIwIDRINGEyIDIgMCAwMC0yIDJ2MTJhMiAyIDAgMDAyIDJoMTZhMiAyIDAgMDAyLTJWNmEyIDIgMCAwMC0yLTJ6bS04IDYuODJMNC4zMiA2aDE1LjM2TDEyIDEwLjgyek00IDE4VjguMTZsOCA1IDgtNVYxOEg0eiIgZmlsbD0iIzY0NjQ2NCIvPjwvc3ZnPg==);
    }

    &::after {
      opacity: 0;
    }
  }

  #bolSmcNavCnt {
    border: 1px solid #fff;
    background-color: #0053c2;
    color: #fff;
    padding: 3px 6px;
    font-size: 12px;
    border-radius: 4px;
    min-width: 18px;
    display: block;
    position: absolute;
    right: 0;

    &:empty {
      display: none;
    }
  }

  @media #{$small-only} {
    display: none !important;
  }
}
