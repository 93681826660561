.bol-header {
  background-color: white;
  padding-top: 10px;
}

.spacing {
  padding-left: 47px;
  margin-top: 20px;
}

.bol-header .multilingual {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  .text {
    font-family: $cnx-medium-family, $cnx-font-fallbacks;
    font-size: 14px;
    position: relative;
    letter-spacing: -0.3px;
  }

  select {
    width: 137px;
    min-width: 137px !important;
    height: 32px !important;
    border: 1px solid black !important;
    font-size: 14px !important;
    line-height: 18px;
    color: black;
    background-color: white;
    padding-bottom: 5px !important;
    font-family: 'cnx-regular', Arial, Helvetica, sans-serif !important; 
    margin: 0;
  }
}

.bol-header .goto-admin {
  font-family: 'cnx-medium', Arial, Helvetica, sans-serif;

  a.goto-admin__link {
    font-size: 16px;
    line-height: 21px;
    font-family: inherit;
  }

  a.goto-admin__link:active {
    color: #00579c;
    text-decoration: none;
  }

  a.goto-admin__link:hover {
    color: #0073cf;
    text-decoration: underline;
  }
}

.bol-header .welcome {  

  font-family: $cnx-medium-family, $cnx-font-fallbacks; 

  .hello-user {
    display: inline;
    color: black !important;
    margin-right: 12px;
    font-size: 18px;    
  }

  .logout-link {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .logout-icon {
    font-family: $cnx-bold-family, $cnx-font-fallbacks; 
    display: inline-block;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
  }
}

.bol-header .ll-date-container {
  margin-bottom: 1.2rem;

  .lldate {
    font-size: 11px;
    position: absolute;
    white-space: nowrap;
    right: 15px;    
  }
}

.bol-header .header-utility {
  background-color: #fff;
  color: #333;
}

.bol-header .header-utility__contain {
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  max-width: 1296px;
  width: 100%;
}

.bol-header .header-utility__brand {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 20px 0;
  height: 65px;
}

.bol-header .header-utility__brand > a {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 0) and (max-width: 425px) {
  .bol-header .header-utility__brand {
    display: block;
  }
}

.bol-header .header-utility__company-logo {
  border-left: 1px solid #d5d5d5;
  margin-left: 20px;
  padding-left: 20px;
  max-height: 65px;
}

.bol-header .header-utility__links {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: auto;
  padding: 0;
  position: relative;
  justify-content: center;
}

.bol-header .header-utility__links > .header-nav {
  display: flex;
  align-items: flex-end;
  margin: 0;
  line-height: 1;
  list-style: none;
}

.bol-header .header-utility__links > .header-nav > .header-nav__item {
  border: none;
  margin: 0;
  padding: 0;
}

.bol-header .header-utility__links > .header-nav > .header-nav__item > a:hover {
  text-decoration: none;
}

.bol-header .header-utility__links > .header-nav > .header-nav__item > :first-child {
  display: flex;
  align-items: center;
  border-top: 1px solid transparent;
  border-right: 0 !important;
  border-left: 0 !important;
  margin: 0;
  padding: 5px 10px;
}

.bol-header .header-utility__links > .header-nav > .header-nav__item:first-child > :first-child {
  border-right: 0 !important;
}

.bol-header .header-utility__links > .header-nav > .header-nav__item[data-bol-nav-item-active='true'] {
  box-shadow: 0 4px 5px rgba(153, 153, 153, 0.35);
  position: relative;
}

.bol-header .header-utility__links > .header-nav > .header-nav__item[data-bol-nav-item-active='true'] > :first-child {
  border-top: 1px solid rgba(151, 151, 151, 0.5);
  border-right: 0 !important;
  background-color: #fff;
  padding-bottom: 10px;
  position: relative;
  z-index: 1079;
}

.bol-header .header-utility__links > .header-nav > .header-nav__item[data-bol-nav-item-active='true'] > .bol-nav-menu {
  margin-top: -1px;
  right: 0;
  z-index: 1078;
}

.bol-header
  .header-utility__links
  > .header-nav
  > .header-nav__item[data-bol-nav-item-active='true']:first-child
  > :first-child {
  border: 0;
}

.bol-header
  .header-utility__links
  > .header-nav
  > .header-nav__item[data-bol-nav-item-active='true']:last-child
  > :first-child {
  border: 0;
}

.bol-header
  .header-utility__links
  > .header-nav
  > .header-nav__item[data-bol-nav-item-active='true']
  + .header-nav__item
  > :first-child {
  border: 0 !important;
}

.bol-header .header-utility__plan {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  height: 12px;
}



@media (max-width: 830px) {
  .bol-header .header-utility__company-logo {
    display: none;
  }
}

@media (max-width: 767px) {
  .bol-header {
    min-height: 90px;
    height: 90px;
    padding-top: 0;
  }

  .bol-header .header-utility {
    height: 0;
  }

  .bol-header .header-utility__brand {
    margin-left: 6px;
  }

  .bol-header .header-utility__merrill-logo {
    position: relative;
    top: -10px;
    width: 160px;
  }

  .bol-header .multilingual {
    display: none !important;
  }

  .bol-header .goto-admin {
    display: none !important;
  }

  .bol-header .welcome {
    display: none !important;
  }

  .bol-header .lldate {
    display: none !important;
  }
}





.spinner2:not([hidden]) {
  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.spinner2::after {
  content: '';
  width: 30px;
  height: 30px;
  border: 6px dotted lightgray;
  border-top: 7px dotted darkgray;
  border-radius: 100%;
  will-change: transform;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
