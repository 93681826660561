.header-mobile {
    background-color: white;
    display: block;
    width: 100%;
    border-bottom: 1px solid lightgray;

    .header-mobile__banner {
        text-align: center;
        padding: 0 10px;
        min-height: 75px;
    }

    .chevy {
        position: absolute;
        top: 38px;
    }

    .chevy__back {
        position: relative;
        float: left;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    .chevy__back:after {
        content: '';
        position: absolute;
        margin-left: 0;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        width: 15px;
        height: 15px;
        border-right: 3px solid #012169;
        border-bottom: 3px solid #012169;
        transform: translateX(50%) translateY(-50%) rotate(135deg);
    }

     .chevy__back:focus {
        outline: 1px dotted gray;
    }

    .merrill-logo {
        margin: 0;
        padding: 0;
        position: relative;
        top: 10px;
        left: 8px;
        width: 160px;
        height: 49px;
    }

    .header-mobile__title {
        position: absolute;
        top: 10px;
        margin: 0;
        padding: 13px 28px;
        width: 98%;
        text-align: center;
        height: 60px;
        line-height: 18px;
    }

     .header-mobile__title span {
        position: relative;
        left: -8px;
        margin: 0;
        padding: 0;
        display: inline;
        width: 81%;
        line-height: 18px;
        font-family: "cnx-regular", Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 20px;
        color: #000;
    }

    .header-mobile__menu-toggle {
        float: right;
        margin: 0;
        width: 24px;
        height: 18px;
        background-color: #02226a;
        border: 0;
        box-shadow: none;
        cursor: pointer;
        display: block;
        font-size: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        top: 26px;
        text-indent: -9999px;
    }

    .header-mobile__menu-toggle span {
        background-color: #fff;
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
        right: 0;
        height: 3px;
        width: 100%;
    }

    .header-mobile__menu-toggle span::after, 
    .header-mobile__menu-toggle span::before {
        background-color: #fff;
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 3px;
        width: 100%;
    }

    .header-mobile__menu-toggle span::before {
        top: -6px;
    }

    .header-mobile__menu-toggle span::after {
        bottom: -6px;
    }

    .header-mobile__menu-toggle:focus {
        outline: 1px dotted gray; 
    }

    .mobile-menu-wrapper {
        width: 100%;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        -webkit-transition: max-height .02s linear, visibility .02s linear, opacity .10s linear;
        transition: max-height .02s linear, visibility .02s linear, opacity .10s linear;
        visibility: hidden;
    }

    .mobile-menu-wrapper[aria-hidden=false] {
        max-height: 800px;
        opacity: 1;
        visibility: visible;
        margin-bottom: 10px; 
    }

    .mobile-menu {
        margin: 0;
        padding: 0;
        width: 100%;
        list-style-type: none;
        display: table;
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
    }

    .mobile-menu__item {
        display: table-row;
        width: 100%;
        font-family: $cnx-family, $cnx-font-fallbacks;
        background-color: #ededed;
    }

    .mobile-menu__item a {
        margin: 0;
        padding: 12px 15px;
        display: inline-block;
        color: #646464;
        text-decoration: none;
        width: 100%;
        font-size: 16px;
        line-height: 1.3em;
        text-align: left;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #d5d5d5;
    }

    .mobile-menu__item a:visited {
        color: #646464;
    }

    .mobile-menu__item a:hover {
        color: #000;
        text-decoration: none;
    }

    .mobile-menu__item a:focus {
        color: #000;
        text-decoration: none;     
    }

    .mobile-menu__item a:focus span {
        outline: 1px dotted gray;       
    }

    .mobile-menu__item--divider {
        display: block;
        background-color: #012169;
        border-top: 1px solid #d1c9c0;
        border-bottom: 1px solid #d1c9c0;
        height: 25px;
    }

    .mobile-menu__item--divider span {
        color: #fff;
        font-size: 12px;
        padding-left: 15px;
        text-transform: uppercase;
        top: 1px;
        position: relative;
    }

}






.bol-header--mobile {
    min-height: auto;
    padding-top: 0;
}

@media (max-width: 767px) {
    .bol-header--mobile {
        min-height: auto;
        height: auto;
    }
}

 .content-title {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background-color: #ededed;
    color: #000;
    font-family: Connections,Arial,Helvetica,sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .4px;
    padding: 10px 15px;
    margin: 0;
}

