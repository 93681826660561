.bol-smc-tab {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style-type: none;
    justify-content: center;
    padding: 0;
    min-width: 60px;

    > a {
      position: relative;
      color: #646464;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex-basis: auto; 
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      padding: 12px 8px 8px;
      padding-left: 0;
      margin-right: 16px;
      width: 100%;
      transition: color 0.3s ease 0s, color 0.3s ease 0s;

      &:focus,
      &:hover {
        color: #0053c2;
        text-decoration: none;
        outline: none;

        &::after {
          background-color: #0053c2;
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 8px);
        height: 2px;
        transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
      }
    }

    &--active {
      > a {
        color: #0053c2;

        &::after {
          background-color: #0053c2;
        }
      }
    }
  }

  @media #{$small-only} {
    display: none;
  }
}
