.bol-component .bol-nav-header-backto {
  background-color: #ededed;

  &__contain {
    margin: 0 auto;
    padding: 15px 10px;
    max-width: 1296px;
    width: 100%;

    @media #{$medium-up} {
      padding-right: 15px;
      padding-left: 15px;
    }

    @media #{$large-up} {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__backto-link {
    color: #0052C2;
    font-size: 16px;
    line-height: inherit;
    text-decoration: none;
    position: relative;
    padding-right: 0;
    padding-left: 21px;

    &:hover {
      color: #0073cf;
      text-decoration: underline;
    }

    &:focus {
      color: #00579c;
      text-decoration: none;
    }

    &::before {
      content: '';
      position: absolute;
      margin-left: 0;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 0;
      width: 8px;
      height: 8px;
      border-right: 2px solid #0053c2;
      border-bottom: 2px solid #0053c2;
      transform: translateY(-50%) rotate(135deg);
    }
  }

  @media #{$small-only} {
    display: none;
  }
}
